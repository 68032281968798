import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';
import useRefreshToken from '../hooks/useRefreshToken';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useTokenManager from '../hooks/useTokenManager';
import Button from './formComponents/Button';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const location = useLocation();

  const isImpersonating = useSelector(
    (state) => state.isAuthorizedReducer.isImpersonating,
  );

  const refreshToken = useRefreshToken();
  useEffect(() => {
    refreshToken(isImpersonating);
  }, [location.pathname]);
  const { revertToAdminToken } = useTokenManager();

  const handleRevert = () => {
    revertToAdminToken();
    window.location.href = '/admin/profile';
  };

  return (
    <div className="flex relative flex-col md:flex-row text-white  min-h-screen w-full  bg-[#190B33]">
      {isImpersonating && (
        <div className="absolute top-4 right-4 flex items-center bg-green-400 rounded-full px-4 py-2 shadow-lg z-[999]">
          <span className="text-white font-semibold mr-4">
            Impersonating user
          </span>
          <Button
            onClick={handleRevert}
            className="bg-primary text-white px-3 py-1 rounded-full"
            text={'Go back as admin'}
          />
        </div>
      )}
      <div
        className={`md:hidden flex px-4 pt-4 z-[999] w-full ${
          isNavOpen && ' fixed top-4'
        } justify-end items-end cursor-pointer`}
        onClick={() => setIsNavOpen((prev) => !prev)}
      >
        {isNavOpen ? (
          <CloseIcon className="text-white text-2xl" />
        ) : (
          <MenuIcon className="text-white text-2xl" />
        )}
      </div>
      <div
        className={` ${isNavOpen ? '' : 'hidden'}    md:block md:w-[100px] `}
      >
        <SideBar setNav={setIsNavOpen} />
      </div>
      <div className=" h-full  w-full md:pl-10 md:pt-10">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
