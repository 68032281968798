import { useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance';
const useGeneratePdf = (id) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleGeneratePdf = async () => {
    setLoadingPdf(true);

    try {
      const pdfData = await axiosInstance.generateUnhealthyImagesPdf(id);
      const url = window.URL.createObjectURL(
        new Blob([pdfData], { type: 'application/pdf' }),
      );
      if (pdfData.error) {
        throw new Error('Error generating PDF');
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `unhealthy_images_project_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success('PDF generated successfully');
    } catch (error) {
      toast.error('Failed to generate PDF');
    } finally {
      setLoadingPdf(false);
    }
  };

  return { loadingPdf, handleGeneratePdf };
};

export default useGeneratePdf;
