import { useCallback, useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosInstance';
import { toast } from 'react-toastify';
import { Icon } from 'leaflet';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
const MAP_TILE_LAYER_URL = 'https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';

const MapLocation = ({
  id,
  longitude = 0,
  latitude = 0,
  handleImageClick,
  imageRefs,
  setImageSelect,
}) => {
  const [items, setItems] = useState([]);

  const legalIcon = useMemo(
    () =>
      new Icon({
        iconUrl:
          'https://static.vecteezy.com/system/resources/previews/023/554/762/original/red-map-pointer-icon-on-a-transparent-background-free-png.png',

        iconSize: [35, 35],
        iconAnchor: [22, 22],
        popupAnchor: [0, -18],
      }),
    [],
  );
  const scrollToImage = (item, index) => {
    setImageSelect(item.id);

    if (imageRefs.current[index]) {
      imageRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const res = await axiosInstance.getData(
        `/projects/images/${id}?page=1&limit=9999&isHealthy=false&isInspected=true`,
      );
      setItems(res?.data?.images);
    } catch (err) {
      toast.error(`Error fetching project: ${err.message}`);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const eventHandlers = (item, index) => ({
    dblclick: () => {
      handleImageClick(item);
    },
    click: () => {
      scrollToImage(item, index);
    },
  });
  const ShowCoordination = () =>
    items
      ?.filter((item) => item?.latitude !== null)
      ?.map((item, index) => (
        <Marker
          icon={legalIcon}
          key={index}
          position={[item?.latitude, item?.longitude]}
          pathOptions={{ color: 'blue' }}
          eventHandlers={eventHandlers(item, index)}
        >
          <Tooltip direction="bottom">
            Double-click to see a popup image.
          </Tooltip>
        </Marker>
      ));

  return (
    <>
      <div className="w-full h-[600px] z-20">
        <MapContainer
          center={[latitude, longitude]}
          zoom={18}
          scrollWheelZoom={false}
          className="w-full h-full "
        >
          <TileLayer
            url={MAP_TILE_LAYER_URL}
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
          <ShowCoordination />
        </MapContainer>
      </div>
    </>
  );
};

export default MapLocation;
