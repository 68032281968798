import React, { useCallback, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { Navigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

const stripePromise = loadStripe(
  'pk_test_51Q8JVFBLKk8vFEIfa1nc56kjki6LSLyTN1XktrLcFBfn4rXY9kuIJFTykMIZmF2qrdjdam8KsbqN2DSKOwGlO3Vt00ltO50dUk',
);

export const CheckoutForm = ({ offer }) => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return axiosInstance
      .postData('/payment/create-checkout-session', {
        projectId: '1',
        price: offer.price,
        name: offer.name,
        description: offer.description,
      })
      .then((data) => data.clientSecret);
  }, []);
  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="w-full  border-2 border-white">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{' '}
          {customerEmail}. If you have any questions, please email{' '}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};
