import { useState } from 'react';
import { CheckoutForm } from '../../components/Payments';
import ModalLayout from '../../components/ModalLayout';
import Button from '../../components/formComponents/Button';

const Credits = () => {
  const [clickToPay, setClickToPay] = useState(false);
  const [credits, setCredits] = useState(500);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const offers = [
    {
      id: 1,
      credits: 100,
      price: 5,
      name: 'Basic Package',
      description: ' (This packge will add to your wallet 100 credits!)',
    },
    {
      id: 2,
      credits: 250,
      price: 10,
      name: 'Standard Package',
      description: ' (This packge will add to your wallet 250 credits!)',
    },
    {
      id: 3,
      credits: 500,
      price: 20,
      name: 'Premium Package',
      description: ' (This packge will add to your wallet 500 credits!)',
    },
  ];

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setClickToPay(true); // Open the modal when an offer is selected
  };

  const transactionHistory = [
    {
      credits: 100,
      amount: '$5',
      status: 'Success',
      date: '2024-10-01',
    },
    {
      credits: 250,
      amount: '$10',
      status: 'Failed',
      date: '2024-09-28',
    },
    {
      credits: 500,
      amount: '$20',
      status: 'Success',
      date: '2024-09-15',
    },
    {
      credits: 100,
      amount: '$5',
      status: 'Success',
      date: '2024-08-20',
    },
    {
      credits: 250,
      amount: '$10',
      status: 'Failed',
      date: '2024-08-01',
    },
    {
      credits: 250,
      amount: '$10',
      status: 'Success',
      date: '2024-09-28',
    },
    {
      credits: 500,
      amount: '$20',
      status: 'Success',
      date: '2024-09-15',
    },
  ];

  return (
    <div className="px-4 w-full lg:flex justify-center items-center">
      <ModalLayout
        open={clickToPay}
        handleClose={() => setClickToPay((prev) => !prev)}
        className=" w-full md:w-[70%] !bg-primary "
      >
        <CheckoutForm offer={selectedOffer} />{' '}
        {/* Pass the selected offer to the CheckoutForm */}
      </ModalLayout>
      <div className=" xl:w-[65%]">
        <div className="py-10 space-y-8">
          {/* Header */}
          <div className="text-4xl font-bold text-white">Credits</div>

          {/* credits Display with Animation */}
          <div className="bg-primary p-6 rounded-lg text-center border-[8px] border-gray-700">
            <div
              className="text-2xl font-semibold text-white animate-pulse"
              style={{
                animationDuration: '2s',
                // animationTimingFunction: 'ease-in-out',
              }}
            >
              z Available Credits
            </div>

            {/* Animated Credits Number */}
            <div
              className="text-6xl font-bold text-[#FC620A] animate-pulse"
              style={{
                animationDuration: '1.5s',
                animationTimingFunction: 'ease-in-out',
              }}
            >
              {credits}
            </div>

            <div className="text-sm text-gray-400">
              You can use your credits to make panel inspection.
            </div>
          </div>
        </div>

        {/* Offers Section */}
        <div className=" md:flex flex-grow gap-4">
          {offers.map((offer) => (
            <div
              key={offer.id}
              className="bg-primary w-full my-4 p-6 rounded-lg shadow-lg text-center justify-items-center border border-gray-700 hover:border-[#FC620A] group transition-all"
            >
              <div className="text-3xl font-bold text-white group-hover:text-[#FC620A]">
                {offer.credits} Credits
              </div>
              <div className="text-xl text-gray-200 mt-2">${offer.price}</div>
              <p className="text-xl text-white mt-2">{offer.name}</p>
              <Button
                className="mt-4 px-4 py-2 rounded-md text-lg font-medium text-white"
                onClick={() => handleOfferClick(offer)}
                text={'Buy Now'}
              ></Button>
            </div>
          ))}
        </div>

        {/* Info Section */}
        <div className="text-lg text-gray-300 space-y-3 pt-6">
          <div>
            <strong className="text-[#FC620A]">Buy More Credits: </strong>
            Buy credits and use them for future inspection!
          </div>
          <div>
            <strong className="text-[#FC620A]">Use Credits: </strong>
            Use your credits to unlock premium features or services.
          </div>
        </div>
      </div>
      {/* History Section */}
      <div className=" lg:px-10 xl:w-[35%]">
        <div className="text-xl pt-10 md:pt-4 lg:pt-0 font-bold text-white mb-4">
          Transaction History
        </div>
        <table className="w-full table-auto  rounded-md overflow-hidden pb-4">
          <thead>
            <tr className="text-left border-b border-gray-400">
              <th className="py-3 px-4 text-white">Credits</th>
              <th className="py-3 px-4 text-white">Amount</th>
              <th className="py-3 px-4 text-white">Status</th>
              <th className="py-3 px-4 text-white">Date</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistory.map((transaction, index) => (
              <tr
                key={index}
                className="text-gray-300 border-b border-gray-700 hover:bg-[#d48659] hover:text-white transition-all"
              >
                <td className="py-3 px-4">{transaction.credits}</td>
                <td className="py-3 px-4">{transaction.amount}</td>
                <td
                  className={`py-3 px-4 ${
                    transaction.status === 'Success'
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                >
                  {transaction.status}
                </td>
                <td className="py-3 px-4">{transaction.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Credits;
