import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Edit from '../assets/icons/Edit';
import Upload from '../assets/icons/Upload';

const ProjectActions = ({
  project,
  loadingFile,
  loadingPdf,
  statistics,
  handleCloseDelete,
  handleEdit,
  setMorePhoto,
  handleGeneratePdf,
}) => {
  return (
    <div className="w-full md:w-[60%] flex flex-wrap md:flex-row justify-center items-center md:justify-end md:items-start gap-4">
      <button
        className={`flex justify-between items-center gap-3 px-3 py-1 rounded-md group text-base 
          ${
            project?.imagecounter === null ||
            project?.isinspected ||
            loadingFile
              ? 'bg-gray-400 text-gray-500'
              : 'bg-gray-700 hover:bg-gray-600 text-white cursor-pointer'
          }`}
        onClick={handleCloseDelete}
        disabled={
          project?.imagecounter === null || project?.isinspected || loadingFile
        }
      >
        <FontAwesomeIcon
          icon={faPlay}
          className={`${loadingFile ? 'text-gray-500' : 'text-white'}`}
        />
        {loadingFile ? 'Please wait...' : 'Start AI processing'}
      </button>

      {project?.imagecounter > 0 && (
        <button
          className="flex justify-between items-center gap-3 px-3 py-1 rounded-md group bg-gray-700 hover:bg-gray-600 text-base"
          onClick={() => setMorePhoto((prev) => !prev)}
        >
          <Upload className="text-white" />
          Upload images
        </button>
      )}

      <button
        className="flex justify-between items-center gap-3 px-3 py-1 rounded-md group bg-gray-700 hover:bg-gray-600 text-base"
        onClick={handleEdit}
      >
        <Edit width="18px" height="18px" fill="#ffff" />
        Edit
      </button>

      {statistics && statistics?.unhealthyPanels > 0 && (
        <button
          disabled={loadingPdf}
          className={`flex justify-between items-center gap-3 px-3 py-1 rounded-md group text-base text-white ${
            loadingPdf
              ? 'bg-gray-400 text-gray-500'
              : 'bg-gray-700 hover:bg-gray-600 cursor-pointer'
          }`}
          onClick={handleGeneratePdf}
        >
          <>
            <FontAwesomeIcon icon={faFilePdf} className="text-white" />
            {loadingPdf ? 'Generating...' : 'Export'}
          </>
        </button>
      )}
    </div>
  );
};

export default ProjectActions;
