import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import Title from '../../components/Title';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const fetchData = async () => {
    try {
      const res = await axiosInstance.getData(`/user/me`);
      setUserData(res.data);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (userData === null) {
    return <Loading fullscreen />;
  }

  return (
    <div className=" flex justify-start flex-col relative w-full h-screen overflow-hidden">
      <Title title={'Profile'} className={'text-xl flex items-start pb-8'} />
      <div className="flex items-top h-full ">
        <div className="bg-primary rounded-lg shadow-lg w-full h-fit max-w-md p-6 text-center">
          <div className="flex flex-col  items-center">
            {userData && (
              <div className="w-20 h-20 custom-button text-white select-none rounded-full flex items-center justify-center text-3xl mb-4">
                {userData?.firstName &&
                  userData?.firstName[0] + userData?.lastName &&
                  userData?.lastName[0]}
              </div>
            )}
            <h1 className="text-2xl font-semibold mb-4">User Profile</h1>
          </div>
          {userData && (
            <div className="text-left">
              <div className="mb-2">
                <strong>First Name:</strong>{' '}
                {userData.firstName && userData?.firstName}
              </div>
              <div className="mb-2">
                <strong>Last Name:</strong>{' '}
                {userData?.lastName && userData?.lastName}
              </div>
              <div className="mb-2">
                <strong>Username:</strong> {userData?.username}
              </div>
              <div className="mb-2">
                <strong>Email:</strong> {userData?.email}
              </div>
              <div className="mb-2 gap-1">
                <strong>Date Created: </strong>
                {userData?.createdAt.split('T')[0]}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
