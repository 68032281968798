import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import useTokenManager from '../../hooks/useTokenManager';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setRole } from '../../Redux/features/authorization/authorizationSlice';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { switchToken } = useTokenManager();
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.getData('/user');
      setUsers(response.result);
    } catch (error) {
      toast.error('Error fetching users: ' + error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleLoginAsUser = async (userId, username) => {
    setLoading(true);
    try {
      const response = await axiosInstance.postData('/auth/impersonate', {
        id: userId,
      });
      if (response.data && response.data.token) {
        switchToken(response.data.token);
        dispatch(setRole());
        navigate('/admin/profile');
        toast.success('Successfully logged in as user ' + username);
      }
    } catch (error) {
      toast.error('Error impersonating user: ' + error);
    }
    setLoading(false);
  };

  return (
    <div className="mx-2 lg:mx-auto my-8 px-4">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-primary shadow-md rounded-2xl">
          <thead>
            <tr>
              <th className="py-3 px-6 bg-gray-800 text-left text-xs font-medium text-white uppercase tracking-wider">
                Name
              </th>
              <th className="py-3 px-6 bg-gray-800 text-left text-xs font-medium text-white uppercase tracking-wider">
                Email
              </th>
              <th className="py-3 px-6 bg-gray-800 text-center text-xs font-medium text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-white hover:text-gray-200">
            {loading
              ? Array.from({ length: 12 }).map((_, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-7 px-6">
                      <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                    </td>
                    <td className="py-7 px-6">
                      <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                    </td>
                    <td className="py-7 px-6 text-center">
                      <div className="h-4 bg-gray-700 rounded w-1/2 mx-auto"></div>
                    </td>
                  </tr>
                ))
              : users &&
                users.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b hover:bg-gray-400 transition duration-200 hover:text-black"
                  >
                    <td className="py-4 px-6">{user.username}</td>
                    <td className="py-4 px-6">{user.email}</td>
                    <td className="py-4 px-6 text-center">
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full lg:w-1/2"
                        onClick={() =>
                          handleLoginAsUser(user.id, user.username)
                        }
                      >
                        Login as {user.username}
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
